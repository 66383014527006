<script>
import rolesService from '@/services/roles.service'

export default {
	props: {
		activePermissions: {
			type: Array,
			default: () => [],
    },
    predefinedRole: {
      type: [Number, String],
      default: '',
    }
  }, 
	computed: {
		permissions() {
			return this.$store.state.permissions.permissions
    },
    roles() {
      return this.$store.state.roles.roles
    }
	},
  mounted() {
    this.$store.dispatch('roles/getAllRoles')
  },
	methods: {
		checkIfPermissionIsActive(name) {
			return (
				!!(this.activePermissions && this.activePermissions.length) &&
				!!this.activePermissions.find(permission => permission.name === name)
			)
		},
		togglePermissionStatus(e, permission) {
			const userHasPermission = this.checkIfPermissionIsActive(permission.name)
			let newPermissions = [...this.activePermissions]
			if (e.target.checked) {
				!userHasPermission && newPermissions.push(permission)
			} else {
				userHasPermission &&
					newPermissions.splice(
						this.activePermissions.findIndex(p => p.id === permission.id),
						1
					)
			}
			this.$emit('updatePermissions', newPermissions)
    },
    selectRole(e) {
      if(!e.target.value) {
        return null
      }else if(e.target.value === 'all') {
        this.$emit('updatePermissions', this.permissions)
      }else if(e.target.value === 'none') {
        this.$emit('updatePermissions', [])
      }else{
        rolesService.getPermissionByRole({ id: e.target.value })
          .then(res => this.$emit('updatePermissions', res.data))
      }
    }
	},
}
</script>

<template>
  <div
    v-if="
      $store.getters['permissions/checkIfUserHasPermission'](
        'permissions.read-list'
      )
    "
  >
    <div class="batchSelect">
      <h3 class="batchSelectTitle">
        {{ $t('batchSelect') }}
      </h3>
      <select
        class="form-control"
        :value="predefinedRole"
        @input="selectRole"
      >
        <option
          value=""
          selected
          disabled
        >
          {{ $t('select') }}
        </option>
        <option value="all">
          {{ $t('all') }}
        </option>
        <option value="none">
          {{ $t('none') }}
        </option>
        <option
          v-for="role of roles"
          :key="`role${role.id}${role.name}`"
          :value="role.id"
        >
          {{ role.name }}
        </option>
      </select>
    </div>
    <h3>{{ $tc('permission', 2) }}</h3>
    <ul class="permissionsList">
      <li
        v-for="permission of permissions"
        :key="permission.name + permission.id"
        class="permission"
      >
        <input
          type="checkbox"
          class="permissionInput"
          :checked="checkIfPermissionIsActive(permission.name)"
          @input="togglePermissionStatus($event, permission)"
        >
        <label>{{ permission.name }}</label>
      </li>
    </ul>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.batchSelectTitle
  margin-bottom: $base-spacing / 2

.permissionsList
  display: grid
  grid-gap: $base-spacing
  padding: 0
  list-style: none

.permission
  display: flex
  align-items: center

.permissionInput
  margin-right: $base-spacing

@include desktop
  .permissionsList
    grid-template-columns: repeat(3, 200px)
  .batchSelect
    width: 300px
    margin-bottom: $base-spacing
</style>

