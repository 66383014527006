<script>
import usersService from '@/services/users.service'
import permissionsService from '@/services/permissions.service'
import libraryService from '@/services/library.service'
import User from '@/data/models/User'
import EditLayout from '@/layout/EditLayout'
import PermissionsCheckList from '@/components/PermissionsCheckList'

export default {
  components: {
    EditLayout,
    PermissionsCheckList,
  },
	data() {
		return {
      type: 'users',
      item: new User,
      photo: null,
      isPasswordOn: false,
      userPermissions: [],
		}
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    isNew() {
      return this.id === 'new'
    },
    title() {
      return this.item ? `${this.item.name ? this.item.name : ''} ${this.item.surname ? this.item.surname : ''}` : ''
    },
    roles() {
      return this.$store.state.roles.roles
    },
  },
	mounted() {
    if(!this.isNew && this.id) {
      this._get()
      this._getUserPermissions()
    }
    this.$store.dispatch('roles/getAllRoles')
	},
	methods: {
    _get() {
      usersService.getById({id: this.id})
        .then(res => {
          this.item = res.data
          res.data.library_id && this._getPhoto({ id: res.data.library_id })
          this.disabledPassword = false
        })
    },
    _getUserPermissions() {
      permissionsService.getByUser({ user: { id: this.id } })
        .then(res => {
          this.userPermissions = res.data
        })
    },
    _getPhoto({ id }) {
      libraryService.getById({ id })
        .then(res => {
          this.photo = res.data
        })
    },
    _saveUserPermissions(id) {
      usersService.updateUserPermissions({ permissions: this.userPermissions, id: id })
      .then(res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$t('userPermissions') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
        })
    },
    create() {
      usersService.create(this.item)
        .then(res => {
          this.$notify({
            message: this.$t('m.itemSuccessfullyAdded', { item: this.$tc('user') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this._saveUserPermissions(res.data.id)
          this.$router.push({ name: 'users' })
        })
    },
    edit() {
      usersService.edit(this.item)
        .then(res => {
          this.isPasswordOn = false
          this.$notify({
            message: this.$t('m.itemSuccessfullySaved', { item: this.$t('user') }),
            icon: "far fa-bell",
            horizontalAlign: 'right',
            verticalAlign: 'top',
            type: 'success',
          })
          this._saveUserPermissions(this.id)
        })
    },
    togglePassword() {
      this.isPasswordOn = !this.isPasswordOn
    },
    updatePermissions(permissions) {
      this.userPermissions = permissions
    },
	}
}
</script>

<template>
  <div>
    <EditLayout
      :item="item"
      :submit-button-text="isNew ? $t('create') : $t('save')"
      :photo="photo"
      supports-photo
      @submit="isNew ? create() : edit()"
    >
      <div
        slot="title"
        class="shadowTitle"
      >
        {{ title }}
      </div>
      <div slot="middle">
        <div class="row">
          <BaseInput 
            v-model="item.quote"
            class="col-12"
            :label="$tc('quote')"
            :placeholder="$t('enterAnAwesomeQuote')"
          />
          <BaseInput 
            v-model="item.website"
            class="col-md-6"
            :label="$tc('website')"
            :placeholder="$t('enterItemUrl', { item : $t('personalWebsite') })"
          />
          <BaseInput 
            v-model="item.twitter"
            class="col-md-6"
            label="Twitter"
            :placeholder="$t('enterItemUrl', { item : 'Twitter' })"
          />
          <BaseInput 
            v-model="item.facebook_page"
            class="col-md-6"
            :label="$t('facebookPage')"
            :placeholder="$t('enterItemUrl', { item : $t('facebookPage') })"
          />
          <BaseInput 
            v-model="item.facebook_profile"
            class="col-md-6"
            :label="$t('facebookProfile')"
            :placeholder="$t('enterItemUrl', { item : $t('facebookProfile') })"
          />
          <BaseInput 
            v-model="item.instagram"
            class="col-md-6"
            label="Instagram"
            :placeholder="$t('enterItemUrl', { item : 'Instagram' })"
          />
          <BaseInput 
            v-model="item.pinterest"
            class="col-md-6"
            label="Pinterest"
            :placeholder="$t('enterItemUrl', { item : 'Pinterest' })"
          />
        </div>
      </div>
      <PermissionsCheckList
        slot="bottom"
        :active-permissions="userPermissions"
        :predefined-role="item.role_id"
        @updatePermissions="updatePermissions"
      />
      <div slot="sidebar-middle">
        <div>
          <label>{{ $tc('role') }}</label>
          <select
            v-model="item.role_id"
            class="form-control"
            :disabled="!$store.getters['permissions/checkIfUserHasPermission']('user.update')"
          >
            <option
              :value="null"
              selected
              disabled
            >
              {{ $t('selectItem', { item: $tc('role') }) }}
            </option>
            <option
              v-for="role of roles"
              :key="'role'+role.id+role.name"
              :value="role.id"
            >
              {{ role.name }}
            </option>
          </select>
        </div>
        <div>
          <BaseInput 
            v-model="item.email"
            label="Email"
            type="email"
            :placeholder="$t('enterItem', { item: 'Email' })"
            autocomplete="off"
          />
        </div>
        <div>
          <BaseInput
            :label="$t('showEmailToPublic')"
            is-slider-checkbox
          >
            <input
              v-model="item.show_email"
              class="form-control"
              type="checkbox"
              true-value="1"
              false-value="0"
            >
          </BaseInput>
        </div>
        <div>
          <BaseInput 
            v-model="item.name"
            :label="$t('firstName')"
            :placeholder="$t('enterItem', { item: $t('firstName') })"
          />
        </div>
        <div>
          <BaseInput 
            v-model="item.surname"
            :label="$t('lastName')"
            :placeholder="$t('enterItem', { item: $t('lastName') })"
          />
        </div>
        <div>
          <BaseInput 
            v-model="item.username"
            :label="$t('username')"
            :placeholder="$t('enterItem', { item: $t('username') })"
          />
        </div>
        <div>
          <BaseInput 
            v-model="item.nickname"
            :label="$t('nickname')"
            :placeholder="$t('enterItem', { item: $t('nickname') })"
          />
        </div>
        <div>
          <BaseInput 
            v-model="item.job_title"
            :label="$t('jobTitle')"
            :placeholder="$t('enterItem', { item: $t('jobTitle') })"
            autocomplete="off"
          />
        </div>
        <div class="password">
          <label>Password</label>
          <div>
            <BaseButton
              v-if="!isNew"
              size="sm"
              type="green"
              gradient
              @click="togglePassword"
            >
              {{ $t('changePassword') }}
            </BaseButton>
          </div>
          <BaseInput 
            v-if="isNew || isPasswordOn"
            v-model="item.password"
            :placeholder="$t('enterItem', { item: $t('password') })"
            type="password"
            autocomplete="new-password"
            autofill="off"
          />
        </div>
      </div>
    </EditLayout>
  </div>
</template>

<style lang="sass" scoped>
@import '~@/assets/sass/design.sass'

.shadowTitle
  color: $text-faded

.password
  margin-bottom: $base-spacing
</style>
